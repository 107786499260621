import { axios } from '@/utils/request';
/**
 * 新增合同
 */
export function addContract(parameter) {
  return axios({
    url: '/projectContract/add',
    method: 'post',
    data: parameter
  });
}
/**
 * 编辑合同
 */
export function editContract(parameter) {
  return axios({
    url: '/projectContract/edit',
    method: 'post',
    data: parameter
  });
}
/**
 * 删除合同
 */
export function delContract(parameter) {
  return axios({
    url: '/projectContract/delete',
    method: 'post',
    data: parameter
  });
}
/**
 * 查看合同详情
 */
export function getContractDetail(parameter) {
  return axios({
    url: '/projectContract/detail',
    method: 'get',
    params: parameter
  });
}
/**
 * 合同列表
 */
export function getContractList(parameter) {
  return axios({
    url: '/projectContract/page',
    method: 'get',
    params: parameter
  });
}
/**
 * 导入工程量清单
 */
export function importDetailList(parameter) {
  return axios({
    url: '/projectContractEngineering/import',
    method: 'post',
    data: parameter
  });
}
/**
 * 导入合同
 */
export function importContract(parameter) {
  return axios({
    url: '/projectContract/import',
    method: 'post',
    data: parameter
  });
}

<template>
  <div>
    <div v-show="indexShow">
      <div class="title">合同列表</div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="合同类型">
          <el-select v-model="queryParam.contractTypeId" placeholder="请选择">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签订日期">
          <el-date-picker
            style="width: 260px;"
            type="daterange"
            v-model="dateValue"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange($event)"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input
            style="width: 310px;"
            v-model.trim="queryParam.searchValue"
            placeholder="合同名称/项目名称/甲方单位/乙方单位/签订人"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getContractList">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table-box">
        <div class="operate-btn">
          <el-button type="primary" icon="el-icon-plus" @click="addContract(1)">新建</el-button>
          <div class="flex-box">
            <el-button type='text' class="export-download mr-16" @click="handleDownload">导入模板下载</el-button>
            <importUpload @get-file="getFile"/>
            <el-button class="ml-16" :disabled="!multipleSelection.length" :loading="exportLoading" @click="exportContract">导出</el-button>
            <el-button type='danger' :disabled="!multipleSelection.length" @click="handleDelete">批量删除</el-button>
          </div>
        </div>
        <el-table
          stripe
          size="medium"
          ref="table"
          row-key="id"
          :data="tableData"
          v-loading="loading"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" fixed="left" width="60"></el-table-column>
          <el-table-column type="index" fixed="left" label="序号" align="center" width="60"></el-table-column>
          <el-table-column
            prop="contractNo"
            label="合同编号"
            align="center"
            show-overflow-tooltip
            width="180"
          >
            <template slot-scope="{ row }">{{row.contractNo || '--'}}</template>
          </el-table-column>
          <el-table-column
            prop="contractName"
            label="合同名称"
            align="center"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="{ row }">
              <span class="details" @click="addContract(2,row.id)">{{row.contractName || '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="contractTypeName"
            align="center"
            show-overflow-tooltip
            width="200"
            label="合同类型"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            width="230"
            show-overflow-tooltip
            align="center"
            label="关联项目"
          >
            <template slot-scope="{ row }">
              <span class="details" @click="goToProject(row)">{{row.projectName || '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="partyAName"
            align="center"
            width="200"
            show-overflow-tooltip
            label="甲方单位"
          >
            <template slot-scope="{ row }">{{row.partyAName || '--'}}</template>
          </el-table-column>
          <el-table-column
            prop="partyBName"
            align="center"
            width="200"
            show-overflow-tooltip
            label="乙方单位"
          >
            <template slot-scope="{ row }">{{row.partyBName || '--'}}</template>
          </el-table-column>
          <el-table-column prop="contractMoney" width="180" align="center" label="合同金额(万元)"></el-table-column>
          <el-table-column prop="signDate" align="center" width="200" label="签订日期">
            <template slot-scope="{ row }">{{getDate(row.signDate)}}</template>
          </el-table-column>
          <el-table-column prop="signUser" align="center" label="签订人">
            <template slot-scope="{ row }">{{row.signUser || '--'}}</template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" width="200" label="创建时间">
            <template slot-scope="{ row }">{{getMinute(row.createTime)}}</template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="180">
            <template slot-scope="{ row }">
              <div class="comm-operation">
                <span class="edit-btn" @click="addContract(2,row.id)">查看</span>
                <span class="edit-btn second" @click="addContract(3,row.id)">修改</span>
                <span class="delete-btn" @click="deleteThis([row.id])">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :total="total"
            :current-page="queryParam.pageNo"
            :page-size="queryParam.pageSize"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 50]"
            layout="total, prev, pager, next, sizes"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div v-show="addFormShow">
      <add-form ref="addForm" @back="backIndex"></add-form>
    </div>
    <div v-if="detailProShow">
      <ProjectDetail @back="handleback" />
    </div>
  </div>
</template>

<script>
import ProjectDetail from '@/views/main/basicPage/personalWork/index.vue';
import { getContractList, delContract, importContract } from '@/api/main/contract';
import importUpload from '@/components/upload/ImportUpload';
import addForm from '@/views/main/basicPage/contractMana/addForm';
import { getBaseList } from '@/api/common/common';
import { exportMethods } from '@/utils/exportMethods.js';
// import projectDetail from '@/views/main/basicPage/personalWork/projectDetail'; projectDetail
export default {
  components: { addForm, importUpload, ProjectDetail },
  name: 'userMana',
  data() {
    return {
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        searchValue: '',
        contractTypeId: '',
        searchBeginTime: '',
        searchEndTime: '',
        projectId: null
      },
      total: 0,
      indexShow: true,
      addFormShow: false,
      detailProShow: false,
      expandRowList: [],
      tableData: [],
      multipleSelection: [],
      projectId: null,
      loading: false,
      dateValue: '',
      typeList: [],
      exportLoading: false,
      proId: null
    };
  },
  created() {
    this.queryParam.projectId = sessionStorage.getItem('projectId');
    this.getContractList();
    this.getTypeList();
  },
  methods: {
    getDate(time) {
      if (!time) {
        return '--';
      }
      return time.substring(0, 10);
    },
    getMinute(time) {
      if (!time) {
        return '--';
      }
      return time.substring(0, 16);
    },
    // 获取合同列表
    getContractList() {
      this.loading = true;
      getContractList(this.queryParam)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取合同类型
    getTypeList() {
      getBaseList({ type: 8 }).then(res => {
        this.typeList = res.data.filter(item => item.id !== 0);
      });
    },
    // 导入模板下载
    handleDownload() {
      //下载模板
      let link = document.createElement('a');
      link.setAttribute('download', '合同批量导入模板');
      link.href = './合同导入.xls';
      link.click();
    },
    // 导出
    exportContract() {
      this.exportLoading = true;
      exportMethods('/projectContract/export', { ids: this.multipleSelection }, '合同列表').finally(() => {
        this.exportLoading = false;
        this.multipleSelection = [];
        this.$refs.table.clearSelection();
      });
    },
    // 合同导入
    getFile(file) {
      let fileData = new FormData();
      fileData.append('file', file);
      fileData.append('projectId', sessionStorage.getItem('projectId'));
      importContract(fileData).then(res => {
        if (res.data.fileInfo) {
          // 导入失败
          this.$message.error(res.data.data);
          const a = document.createElement('a');
          const e = document.createEvent('MouseEvents');
          e.initEvent('click', false, false);
          a.href = res.data.fileInfo.filePath;
          a.download = res.data.fileInfo.fileOriginName;
          a.dispatchEvent(e);
          return;
        }
        this.$message.success(res.data.data);
        this.getContractList();
      });
    },
    // 跳转关联项目
    goToProject(data) {
      if (!data.projectId) return;
      this.proId = data.projectId;
      this.indexShow = false;
      this.detailProShow = true;
    },
    // 日期选择
    dateChange(val) {
      this.queryParam.searchBeginTime = val[0];
      this.queryParam.searchEndTime = val[1];
    },
    handleSizeChange(size) {
      this.queryParam.pageSize = size;
      this.queryParam.pageNo = 1;
      this.getContractList();
    },
    handleCurrentChange(page) {
      this.queryParam.pageNo = page;
      this.getContractList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id);
    },
    // 新建/查看/编辑  1/2/3
    addContract(type, id) {
      const data = {
        type,
        id
      };
      this.indexShow = false;
      this.addFormShow = true;
      this.$refs.addForm.init(data);
    },
    // 批量删除
    handleDelete() {
      this.deleteThis(this.multipleSelection);
    },
    // 删除
    deleteThis(ids) {
      this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delContract({ ids }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getContractList();
          });
        })
        .catch(() => {});
    },
    resetForm() {
      this.queryParam = {
        pageNo: 1,
        pageSize: 10,
        searchValue: '',
        contractTypeId: '',
        searchBeginTime: '',
        searchEndTime: '',
        projectId: sessionStorage.getItem('projectId')
      };
      this.dateValue = '';
      this.getContractList();
    },
    backIndex(type) {
      this.indexShow = true;
      this.addFormShow = false;
      this.detailProShow = false;
      if (type === 2) {
        // 新增 第一页
        this.queryParam.pageNo = 1;
        this.getContractList();
      }
      if (type === 1) {
        // 编辑 刷新当前页
        this.getContractList();
      }
    },
    handleback() {
      this.indexShow = true;
      this.addFormShow = false;
      this.detailProShow = false;
    }
  }
};
</script>

<style lang='less' scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
}
.demo-form-inline {
  padding: 16px 0 0 16px;
  border-bottom: 1px solid #e8e8e8;
}
.table-box {
  padding-top: 24px;
  .operate-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .flex-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .export-download{
      color: #117BF7;
      border-bottom: 1px solid #117BF7;
    }
  }
}
.pagination {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.details {
  color: #117bf7;
  cursor: pointer;
}
/deep/.el-table__empty-text {
  text-align: left;
}
</style>

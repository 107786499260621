<template>
<div>
  <div class="top-title">
    <div class='title'>新增合同</div>
    <el-button plain @click="handleBack(false)">返回</el-button>
  </div>
  <unfoldAndStow name='项目信息'>
    <el-form
      class="pro-form"
      ref="form"
      v-loading='formLoading'
      :model="form"
      :rules="rules"
      label-width="150px"
    >
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="合同编号" prop="contractNo">
            <el-input v-model.trim="form.contractNo" :disabled="isShowDetail" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="contractName" label="合同名称">
            <el-input v-model.trim="form.contractName" :disabled="isShowDetail" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="contractTypeId" label="合同类型">
            <el-select filterable v-model="form.contractTypeId" :disabled="isShowDetail" placeholder="请选择(可搜索)" style="width: 100%;">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="signDate" label="签订日期">
            <el-date-picker
              v-model="form.signDate"
              type="date"
              value-format="yyyy-MM-dd"
              :disabled="isShowDetail"
              placeholder="请选择"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="contractMoney" label="合同金额(万元)">
            <el-input v-model.trim="form.contractMoney" @input="numberInput" clearable :disabled="isShowDetail" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="金额大写">
            <el-input v-model="form.moneyCost" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="partyA" label="甲方单位">
            <el-select filterable v-model="form.partyA" multiple
                       :disabled="isShowDetail" placeholder="请选择(可搜索)" style="width: 100%;" @change="selectPartA">
              <el-option v-for="item in companyData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="partyB" label="乙方单位">
            <el-select filterable v-model="form.partyB" multiple :disabled="isShowDetail" placeholder="请选择(可搜索)" style="width: 100%;" @change="selectPartB">
              <el-option v-for="item in companyData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="paymentMethod" label="付款方式">
            <el-select v-model="form.paymentMethod" :disabled="isShowDetail" placeholder="请选择" style="width: 100%;">
              <el-option label="按进度付款" :value="1"></el-option>
              <el-option label="按合同付款" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item prop="settlementMethod" label="结算方式">
            <el-select v-model="form.settlementMethod" :disabled="isShowDetail" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in settlementMethodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="预收款(万元)" prop="advanceCollection">
            <el-input v-model.trim="form.advanceCollection" clearable :disabled="isShowDetail" :placeholder="advanceCollectionPlaceholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="保证金(万元)" prop="deposit">
            <el-input v-model.trim="form.deposit" clearable :disabled="isShowDetail" :placeholder="depositPlaceholder"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <el-form-item label="收款条件">
            <el-input
              v-model.trim="form.collectionCondition"
              type="textarea"
              maxlength="500"
              :rows="5"
              resize="none"
              :disabled="isShowDetail"
              show-word-limit
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <el-form-item label="主要条款">
            <el-input
              v-model.trim="form.clause"
              type="textarea"
              maxlength="500"
              :disabled="isShowDetail"
              :rows="5"
              resize="none"
              show-word-limit
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="签订人">
            <tree-select :data="signUserData"
                         :isDisabled="isShowDetail"
                         :defaultProps="defaultProps"
                         :nodeKey="nodeKey"
                         :placeholder='placeholder'
                         :checkedKeys="defaultCheckedUserKeys"
                         :clearable="true"
                         @popoverHide="popoverHide"></tree-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <el-form-item label="备注">
            <el-input
              v-model.trim="form.remark"
              type="textarea"
              maxlength="500"
              :disabled="isShowDetail"
              :rows="5"
              resize="none"
              show-word-limit
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </unfoldAndStow>
  <unfoldAndStow name='附件'>
    <div style="margin: 16px 40px 20px;">
       <Upload
         :tableFileList="tableFileList"
         :fileIdList="fileIdList"
         :disabled="isShowDetail"
         @get-file-data="getFileData"/>
    </div>
  </unfoldAndStow>
  <unfoldAndStow name='工程量清单'>
    <div class="detail-list">
      <div class='list-btn' v-if="!isShowDetail">
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="addLine">增行</el-button>
          <el-button type='danger' icon="el-icon-close" :disabled="!this.listData.length" @click="deleteData">删行</el-button>
        </div>
        <div class="right-btn">
<!--          <a href='./合同清单导入模板.xls' download='合同清单导入模板' class="export-download mr-16">导入模板下载</a>-->
          <el-button type='text' class="export-download mr-16" @click="handleDownload">导入模板下载</el-button>
          <importUpload @get-file="getFile"/>
        </div>
      </div>
      <el-table
        stripe
        size="medium"
        ref="listTable"
        :data="listData"
        style='width: 100%;margin-top: 16px;'
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          align='center'
          v-if="!isShowDetail"
          width="60">
        </el-table-column>
        <el-table-column
          type='index'
          label='序号'
          align='center'
          width='60'>
        </el-table-column>
        <el-table-column
          prop='engineeringNo'
          label='编号'
          align='center'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.engineeringNo" clearable :disabled="isShowDetail" maxlength="50" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='detailedList'
          label='清单子母'
          align='center'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.detailedList" clearable :disabled="isShowDetail" maxlength="50" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='unit'
          align='center'
          label='单位'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.unit" clearable :disabled="isShowDetail" maxlength="50" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='quantities'
          align='center'
          label='工程量'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.quantities" clearable :disabled="isShowDetail" @input="value => numberCheck(value,1)" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='price'
          align='center'
          label='综合单价'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.price" clearable :disabled="isShowDetail" @input="value => numberCheck(value,2)" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='total'
          align='center'
          label='合价'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.total" clearable :disabled="isShowDetail" @input="value => numberTotalCheck(value)" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop='remark'
          align='center'
          label='备注'>
          <template slot-scope='{ row }'>
            <el-input v-model.trim="row.remark" clearable :disabled="isShowDetail" maxlength="50" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">
        <div class="total-item">
          <span class="item-label">合计：</span>
          <el-input class="mr-16" v-model.trim="inventoryCost" clearable :disabled="isShowDetail" @input="value => computeCombinedInput(value)"></el-input>
        </div>
<!--        <div class="total-item">合计：<span>{{inventoryCost}}</span></div>-->
        <div class="total-item">金额大写：<span>{{inventoryBig}}</span></div>
      </div>
    </div>
  </unfoldAndStow>
  <div class="submit-btn">
    <el-button style="margin-right: 16px;" @click="handleBack(0)">取消</el-button>
    <el-button v-if="this.editType !== 2" type="primary" @click="handleSubmit" :loading="submitLoading">保存</el-button>
  </div>
</div>
</template>

<script>
import unfoldAndStow from '@/views/main/basicPage/_components/unfoldAndStow';
import importUpload from '@/components/upload/ImportUpload'
import Upload from '@/components/upload/UploadFile'
import TreeSelect from '@/components/treeSelect/index.vue';
import { getContractDetail, addContract, editContract, importDetailList } from '@/api/main/contract'
import { getBaseList, getSysUser } from '@/api/common/common';
import { getAllRelatedName } from '@/api/main/projectMana'
import { numberFun } from '@/utils/capitalizeNumber';
import { debounce } from 'lodash'
export default {
  name: 'addForm',
  components: {
    unfoldAndStow,
    Upload,
    TreeSelect,
    importUpload
  },
  data() {
    // 金额校验
    const checkDeptTel = (rule, value, callback) => {
      // 正数正则
      const numberReg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,6})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if (!value) {
        callback();
      } else {
        if (!numberReg.test(value)) {
          callback('请输入数字，小数点后不超过6位！');
        } else if (value >= 100000000) {
          callback('金额最大不超过8位！');
        } else {
          callback();
        }
      }
    };
    return{
      form: {
        advanceCollection: '',
        clause: '',
        collectionCondition: '',
        contractMoney: '',
        contractName: '',
        contractTypeId: '',
        deposit: '',
        moneyCost: '',
        contractNo: null,
        signDate: null,
        paymentMethod: null,
        settlementMethod: null,
        partyA: [],
        partyB: [],
        remark: '',
      },
      partyA: '',
      partyB: '',
      submitLoading: false,
      formLoading: false,
      rules: {
        contractNo: [
          { max: 100, message: '长度限制在100个字符以内', trigger: 'blur' }
        ],
        contractName: [
          { required: true, message: '请输入', trigger: 'blur' },
          { max: 100, message: '长度限制在100个字符以内', trigger: 'blur' }
        ],
        contractTypeId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        signDate: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        contractMoney: [
          { required: true, message: '请输入', },
          { validator: checkDeptTel, trigger: 'change' }
        ],
        partyA: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        partyB: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paymentMethod: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        settlementMethod: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        advanceCollection: [
          { validator: checkDeptTel, trigger: 'change' }
        ],
        deposit: [
          { validator: checkDeptTel, trigger: 'change' }
        ],
      },
      numberFlag1: true,
      numberFlag2: true,
      signUserData: [], //签订人
      defaultCheckedUserKeys: [], // 签订人回显
      checkedData: '', // 签订人选中
      companyData: [], // 甲方乙方单位
      inventoryBig: '', // 清单金额大写
      inventoryCost: '', // 清单金额
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      nodeKey: 'id',
      placeholder: '请选择',
      advanceCollectionPlaceholder: '请选择',
      depositPlaceholder: '请选择',
      // 附件
      tableData: [],
      // 清单列表
      listData: [],
      editType: 1,
      isShowDetail: false,
      multipleSelection: [],
      typeList: [],// 合同类型
      // 结算方式
      settlementMethodList: [
        {id:1,name:'按月结算'},
        {id:2,name:'分段结算'},
        {id:3,name:'目标结算'},
        {id:4,name:'竣工后一次结算'},
        {id:5,name:'其他'},
      ],
      tableFileList: [], // 回显上传附件
      fileIdList: [], //回显文件id集合
      newFileIds: [], // 上传文件ids
    }
  },
  methods: {
    init(data) {
      //  type 新建/查看/编辑  1/2/3  id
      this.editType = data.type
      this.getTypeList()
      this.getSysUser()
      this.getAllRelatedName()
      if(data.type === 2){
        this.isShowDetail = true
        this.$refs.form.clearValidate()
      }
      if(data.id) {
        this.formLoading = true
        this.defaultCheckedUserKeys = []
        getContractDetail({id: data.id}).then(res => {
          const data = res.data
          this.form = {
            id: data.id,
            advanceCollection: data.advanceCollection,
            clause: data.clause || "无",
            collectionCondition: data.collectionCondition || "无",
            contractMoney: data.contractMoney,
            contractName: data.contractName,
            contractNo: data.contractNo || "无",
            contractTypeId: data.contractTypeId,
            deposit: data.deposit,
            moneyCost: data.moneyCost,
            paymentMethod: data.paymentMethod,
            settlementMethod: data.settlementMethod,
            signDate: data.signDate,
            remark: data.remark  || "无",
            partyA: data.partyA?.split(','),
            partyB: data.partyB?.split(','),
          }
          this.partyA = data.partyA
          this.partyB = data.partyB
          if(!data.advanceCollection) {
            this.advanceCollectionPlaceholder = '无'
          }
          if(!data.deposit) {
            this.depositPlaceholder = '无'
          }
          // 上传附件回显
          if(data.fileInfos.length) {
            this.tableFileList = data.fileInfos
            data.fileInfos.map(item => {
              item.operatTime = item.createTime
              this.fileIdList.push(item.fileId);
            })
          }
          // 工程量清单
          if(data.inventoryList.length) {
            data.inventoryList.forEach((item,index) => {
              item.idIndex = index
            })
          }
          this.listData = data.inventoryList
          if(!data.inventoryCost) {
            this.computeTotal()
          }else {
            this.inventoryBig = data.inventoryBig
            this.inventoryCost = data.inventoryCost
          }
          // 签订人
          if(data.signUserId) {
            this.defaultCheckedUserKeys.push(data.signUserId)
          }else{
            this.placeholder = '无'
          }
        }).finally(() => {
          this.formLoading = false
        })
      }
    },
    // 手动输入单价数量正数正则校验
    numberCheck: debounce(function(value,type){
      const numberReg12 = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if(!numberReg12.test(value)){
        this.$message.error('请输入数字，小数点后不超过2位！')
        type === 1 ? this.numberFlag1 = false : this.numberFlag2 = false
        return
      }else if (value >= 10000000000) {
        this.$message.error('最大不超过10位！');
        type === 1 ? this.numberFlag1 = false : this.numberFlag2 = false
        return
      }else{
        type === 1 ? this.numberFlag1 = true : this.numberFlag2 = true
        this.computeTotal()
      }
    },500),
    // 计算总价
    computeTotal() {
      let totalMoney = 0
      this.listData.forEach(item => {
        if(item.price && item.quantities) {
          item.total = item.price * item.quantities || 0
          totalMoney += Number(item.total)
        }
      })
      this.inventoryCost = totalMoney || 0
      this.inventoryBig = numberFun(this.inventoryCost);
    },
    // 手动输入总计正则校验
    numberTotalCheck: debounce(function(value){
      const numberReg12 = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if(!numberReg12.test(value)){
        this.$message.error('请输入数字，小数点后不超过2位！')
        return
      }
      if (value >= 10000000000) {
        this.$message.error('最大不超过10位！');
        return
      }
      this.computeTotalInput()
    },500),
    // 计算总价
    computeTotalInput() {
      let totalMoney = 0
      this.listData.forEach(item => {
        totalMoney += Number(item.total)
      })
      this.inventoryCost = totalMoney || 0
      this.inventoryBig = numberFun(this.inventoryCost);
    },
    // 手动输入合价
    computeCombinedInput:debounce(function(value){
      const numberReg12 = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if(!numberReg12.test(value)){
        this.$message.error('请输入数字，小数点后不超过2位！')
      }
      if (value >= 10000000000) {
        this.$message.error('最大不超过10位！');
      }
      this.getInventoryCost(value)
    }),
    // 计算合价
    getInventoryCost(value) {
      this.inventoryCost = value
      this.inventoryBig = numberFun(this.inventoryCost);
    },
    // 获取合同类型
    getTypeList() {
      getBaseList({ type: 8 }).then(res => {
        this.typeList = res.data.filter(item => item.id !==0);
      });
    },
    // 获取相关方 单位
    getAllRelatedName() {
      getAllRelatedName().then(res => {
        this.companyData = res.data
      })
    },
    // 获取签订人列表
    getSysUser() {
      getSysUser().then(res => {
        this.signUserData = res.data
      })
    },
    handleBack(type) {
      this.$emit('back', type)
      this.reset()
    },
    reset() {
      this.isShowDetail = false
      this.editType = 1
      this.defaultCheckedUserKeys = []
      this.listData = []
      this.tableData = []
      this.inventoryBig = ''
      this.inventoryCost = ''
      this.$refs.form.resetFields()
      this.tableFileList = []
      this.fileIdList = []
      this.form = {
        contractTypeId: null,
        signDate: null,
        paymentMethod: null,
        settlementMethod: null,
        partyA: [],
        partyB: [],
      }
      this.partyA = ''
      this.partyB = ''
      this.checkedData = null
      this.advanceCollectionPlaceholder = '请输入'
      this.depositPlaceholder = '请输入'
      this.placeholder = '请选择'
    },
    // 金额大写
    numberInput: debounce(function(val){
      const numberReg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,6})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if (!numberReg.test(val)) {
        this.form.moneyCost = '';
      } else if (val >= 100000000) {
        this.form.moneyCost = '';
      } else {
        this.form.moneyCost = numberFun(val * 10000);
      }
    },500),
    // 选择签订人
    popoverHide(checkedIds, checkedData) {
      this.checkedData = checkedData //对象
    },
    selectPartA(val) {
      this.partyA = val.join(',')
    },
    selectPartB(val) {
      this.partyB = val.join(',')
    },
    // 保存
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        if(!this.isAllEdit()) {
          this.$message.error('请填写完清单！')
          return
        }
        this.submitLoading = true;
        // 签订人
        if(this.checkedData) {
          this.form.signUserId = this.checkedData.id
        }
        this.form.partyA = this.partyA // 甲方
        this.form.partyB = this.partyB // 乙方
        this.form.inventoryBig = this.inventoryBig // 清单金额大写
        this.form.inventoryCost = this.inventoryCost // 清单金额
        this.form.inventoryList = this.listData // 清单列表
        this.form.project_id = sessionStorage.getItem('projectId')
        let url = ''
        let msg = ''
        this.editType === 1 ? url = addContract(this.form) : url = editContract(this.form)
        this.editType === 1 ? msg = '新增合同成功' : msg = '编辑合同成功'
        url.then(res => {
          this.$message.success(msg);
          this.editType === 3 ? this.handleBack(1) : this.handleBack(2)
        }).finally(() => {
          this.submitLoading = false;
        })
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清单是否填写完整
    isAllEdit() {
      let flag = true
      if(this.listData.length) {
        const dataItem = this.listData[this.listData.length-1]
        flag = dataItem.engineeringNo && dataItem.detailedList && dataItem.unit && dataItem.quantities && dataItem.total
      }
      return flag
    },
    // 添加行
    addLine() {
      if(!this.isAllEdit()) {
        this.$message.error('请填写完清单！')
        return
      }
      if(!this.numberFlag1 || !this.numberFlag2) {
        this.$message.error('请输入正确的数字！')
        return
      }
      const obj = {
        idIndex: this.listData.length,
        engineeringNo: '',
        detailedList: '',
        unit: '',
        quantities: '',
        total: '',
        remark: ''
      }
      this.listData.push(obj)
    },
    // 删除行数据
    deleteData() {
      if(this.multipleSelection.length) {
        // 删除勾选数据
        this.multipleSelection.forEach(item=> {
          this.listData.forEach((data,index )=> {
            if (data.idIndex === item.idIndex) {
              this.listData.splice(index,1);
            }
          })
        })
      }else {
        // 没有勾选 删最后一行
        this.listData.pop()
      }
      this.numberFlag1 = this.numberFlag2 = true
      this.computeTotal()
    },
    //上传文件返回的文件id []
    getFileData(fileIds) {
      this.form.fileIds = fileIds
    },
    // 导入模板下载
    handleDownload() {
      //下载模板
      let link = document.createElement('a');
      link.setAttribute('download', '合同清单导入模板');
      link.href = './合同清单导入模板.xls';
      link.click();
    },
    // 清单导入
    getFile(file) {
      let fileData = new FormData()
      fileData.append('file',file)
      importDetailList(fileData).then(res => {
        this.$message.success('清单导入成功!')
        this.listData = res.data
        if(!this.inventoryCost) {
          this.computeTotal()
        }
      })
    },
  }
};
</script>

<style lang='less' scoped>
.top-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 16px;
}
.detail-list{
  margin:16px 40px;
  .list-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .export-download{
      color: #117BF7;
      border-bottom: 1px solid #117BF7;
    }
    .right-btn{
      display: flex;
      justify-content: flex-start;
    }
  }
}
.total{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin: 20px 0 80px;
  font-weight: bold;
  .total-item{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .item-label{
      width: 80px;
      text-align: right;
    }
  }
}
.submit-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-top:1px solid #E8E8E8;
  padding-top: 16px;
}
</style>

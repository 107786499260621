import { axios } from '@/utils/request';
/**
 * 项目分页列表
 */
export function getProList(parameter) {
  return axios({
    url: '/project/pageList',
    method: 'get',
    params: parameter
  });
}
/**
 * 新增保存按钮
 */
export function postAdd(parameter) {
  return axios({
    url: '/project/addItem',
    method: 'post',
    data: parameter
  });
}

export function getType(parameter) {
  return axios({
    url: '/typeBase/getList',
    method: 'get',
    params: parameter
  });
}
/**
 * 删除
 */
export function deleteProject(parameter) {
  return axios({
    url: '/project/delete',
    method: 'post',
    data: parameter
  });
}
/**
 * 建设单位
 */
export function getAllRelatedName(parameter) {
  return axios({
    url: '/relatedParty/getAllRelatedName',
    method: 'get',
    params: parameter
  });
}
/**
 * 编辑保存项目
 */
export function postEdit(parameter) {
  return axios({
    url: '/project/editItem',
    method: 'post',
    data: parameter
  });
}
/**
 * 项目导入
 */
export function importProject(parameter) {
  return axios({
    url: '/project/importExcelData',
    method: 'post',
    data: parameter
  });
}
